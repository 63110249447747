.vendor-login {
    background-color: #FFBA24; /* Consistent background color */
    color: blue; /* Text color */
    font-family: 'Roboto', sans-serif; /* Consistent font choice */
    padding: 20px; /* Padding for layout */
  }
  
  .vl-h2 {
    text-align: center;
    font-weight: bold; /* Bold heading text */
  }
  
  .error-message {
    color: red; /* Error message styling */
    text-align: center; /* Center align error message */
  }
  
  .login-form {
    margin: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: white; /* Form background color */
  }
  
  .login-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .login-form input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-btn {
    background-color: blue;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin-top: 10px;
    width: 100%;
  }
  
  .login-btn:hover {
    background-color: darkblue;
  }

  /* Additional styles for reset password form */
.reset-password-form {
  margin-top: 20px;
  text-align: center;
}

.reset-password-form input[type="email"] {
  margin-bottom: 10px;
}

.reset-password-form button {
  width: 100%;
}
  
  /* Responsive adjustments for mobile devices */
  @media (max-width: 768px) {
    .login-form label {
      font-size: 1rem;
    }
  
    .vl-h2 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .login-form label {
      font-size: 0.9rem;
    }
  
    .vl-h2 {
      font-size: 1.3rem;
    }
  }
  