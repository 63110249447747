/* Map Container Styles */
.map-container {
    color: blue;
    margin: 0 auto;
    max-width: 800px; /* Adjust as needed */
    max-height: 1000px;
    padding: 20px;
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center align the text */
}

.map-container h2 {
    color: #333; /* Dark text color for the heading */
    margin-bottom: 20px;
}

/* Google Map Container Styles */
.google-map-container {
    margin: 20px auto;
    max-width: 800px; /* Adjust as needed */
    padding: 20px;
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center align the text */
}

.google-map-container h2 {
    color: #333; /* Dark text color for the heading */
    margin-bottom: 20px;
}

.google-map-container iframe {
    width: 100%; /* Full width */
    height: 450px; /* Adjust height as needed */
    border: none; /* Remove border */
    border-radius: 4px; /* Slightly rounded corners for the map */
}
