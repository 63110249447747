body {
    color: #666;
}

h1, h2, h3, h4, h5, h6 {
    color: #111;
}

.l-box {
    padding: 2em;
}

.header .pure-menu {
    border-bottom-color: black;
    border-radius: 0;
}

.pure-menu-link {
    padding: 1em 0.7em;
}

/* Gallery container */
.gallery-container {
    padding: 2em;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
    display: grid; /* Use grid layout */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid items */
    gap: 20px; /* Space between grid items */
}

/* Individual photo box */
.photo-box {
    position: relative;
    cursor: pointer; /* Change cursor on hover */
}

.photo-box img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    object-fit: cover; /* Cover the container, might crop the image */
}

/* Title and delete button */
.photo-box aside {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1em 0.5em;
    color: white;
    width: 100%;
    font-size: 80%;
    text-align: right;
    background: linear-gradient(to bottom, rgba(16,27,30,0) 0%,rgba(12,2,2,1) 90%);
}

/* Footer styles */
.footer {
    background: #111;
    color: #666;
    text-align: center;
    padding: 1em;
    font-size: 80%;
}

/* Modal styles for full-size image view */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.image-modal img {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    color: white;
    cursor: pointer;
}

@media (min-width: 30em) {
    /* Larger screen adjustments */
    .photo-box, .text-box {
        text-align: left;
    }
}
