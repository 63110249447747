/* Login.css */

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 40px auto;
}

.login h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.login form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.login form div {
    margin-bottom: 20px;
    width: 100%;
}

.login label {
    display: block;
    margin-bottom: 8px;
    color: #666;
}

.login input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.login button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login button:hover {
    background-color: #0056b3;
}

.error, .success {
    width: 100%;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
}

.error {
    background-color: #ffb3b3;
    color: #d32f2f;
}

.success {
    background-color: #b2ffb3;
    color: #388e3c;
}

/* Additional styles for reset password form */
.reset-password-form {
    margin-top: 20px;
    text-align: center;
  }
  
  .reset-password-form input[type="email"] {
    margin-bottom: 10px;
  }
  
  .reset-password-form button {
    width: 100%;
  }