/* Layout and Visual Improvements for Services */
.services-container {
    padding: 40px;
    background: url('./background.gif') no-repeat center center fixed;
    background-size: cover;
}

.services-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.create-link {
    background-color: #007bff;
    padding: 10px 15px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
}

.create-link:hover {
    background-color: #0056b3;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.service-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    transition: transform 0.3s;
    overflow: hidden;
}

.service-card:hover {
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.service-actions {
    margin-top: 10px;
}

.edit-link, .delete-link {
    margin-right: 10px;
    text-decoration: none;
    color: #007bff;
}

.delete-link {
    color: red;
}

.services-container h2 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    color: #ffffff;
    position: relative;
}

.services-container h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 80px;
    height: 3px;
    
    background-color: #007bff;
    transform: translateX(-50%);
}