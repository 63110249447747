.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(33, 37, 41, 0.8); /* Opaque dark gray */
    z-index: 100;
    transition: top 0.3s ease; /* Smooth transition for top property */
}

.header-container.top-header-not-active {
    top: -80px; /* Adjust this value to hide the top-header */
}

.header-container .pure-menu-list {
    display: flex; /* Aligns the list items in a row */
    padding-left: 0; /* Removes default padding */
    list-style-type: none; /* Removes default list styling */
    justify-content: center; /* Center align items */
    align-items: center; /* Vertical centering */
    margin-top: 0; /* No margin when top-header is not active */
    transition: margin-top 0.3s ease; /* Smooth transition for margin-top */
}

.header-container:not(.top-header-not-active) .pure-menu-list {
    margin-top: 90px; /* Adjust based on the height of your top-header */
}

.header-container .pure-menu-item {
    margin-right: 10px; /* Space between items */
}

.pure-menu-horizontal .pure-menu-link, .pure-menu-horizontal .pure-menu-item {
    color: white; /* White text for menu items */
}

.header-container .pure-menu-item.active .pure-menu-link {
    color: blue; /* Blue text for active menu item */
}

.hamburger {
    display: none;
    cursor: pointer;
    justify-content: center; 
    align-items: center;
    height: 40px; 
}

.hamburger span {
    display: block;
    justify-content: center; 
    align-items: center;
    width: 30px;
    height: 2px;
    background-color: white;
    margin: 4px 0;
}

.nav-button {
    background-color: rgba(50, 50, 50, 0.8); /* Darker shade of opaque gray */
    color: white; /* White text */
    padding: 10px 20px; /* Padding for size */
    border: none; /* Removes border */
    border-radius: 4px; /* Rounded corners */
    text-align: center;
    text-decoration: none; /* Removes underline */
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer; /* Cursor to pointer on hover */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Modern font */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.nav-button:hover {
    background-color: rgba(70, 122, 255, 0.8); /* Slightly darker on hover */
}

.active-link {
    background-color: rgba(70, 122, 255, 0.8);
    color: rgba(0, 0, 0, 0.8); /* Temporarily added for debugging */
}

.logo-container {
    /* Logo on the left */
    float: left;
}

.right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px; /* Adjust as needed */
}

.time {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif; 
    color: black;
    text-align: center;
}

.social-media-links {
    /* Additional styling */
    margin-top: 5px; /* Space between times and social media links */
    text-align: center;
    font-size: 1.2em;
}

.top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 5px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px; /* Adjust height as needed */
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.top-header-active {
    transform: translateY(0);
    opacity: 1;
}

.header-container.top-header-not-active .top-header {
    transform: translateY(-100%);
    opacity: 0;
}

.hamburger-label {
    color: white; /* White text color */
    text-align: center; /* Center the text */
    font-size: 1rem; /* Adjust font size as needed */
    margin-bottom: 5px; /* Space between label and hamburger icon */
    display: none; /* Hidden by default */
}

@media (max-width: 768px) {
    .hamburger {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        height: 40px; /* Height of the hamburger icon */
    }
    
    .hamburger span {
        display: block;
        width: 30px; /* Width of each line */
        height: 2px; /* Height of each line */
        background-color: white; /* Color of the lines */
        margin: 6px 0; /* Space between the lines */
    }

    .hamburger-label {
        display: block; /* Show the label on small screens */
    }
    
    .top-header {
        display: none;
    }

    .header-container .pure-menu-list {
        position: static;
        top: 100%; /* Position below the header */
        left: 0;
        right: 0;
        background-color: rgba(33, 37, 41, 0.8); /* Same as header background */
        display: none; /* Hidden initially */
        flex-wrap: wrap; /* Allows items to wrap into a new line */
        justify-content: center; /* Center align items */
        padding: 10px; /* Add some padding */
        transition: transform 0.3s ease, opacity 0.3s ease;
        transform: translateY(-100%); 
        opacity: 0; 
    }

    .header-container .pure-menu-list.active {
        display: flex; /* Display as flexbox when active */
        transform: translateY(0); /* Slide to visible position */
        opacity: 1; /* Fully visible */
    }

    .header-container .pure-menu-item {
        width: 50%; /* Each item takes up half the width */
        margin-right: 0; /* Remove right margin */
        margin-bottom: 10px; /* Space between items */
        text-align: center; /* Center-align the text and buttons */
    }

    .nav-button {
        padding: 8px 15px; /* Smaller padding for mobile */
        font-size: 14px; /* Smaller font size for mobile */
        margin: 5px auto; /* Center buttons within the menu items */
        display: block; /* Makes the button fill the width of the menu item */
    }
}
