/* Portfolio.css */

.portfolio-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f4f4f4; /* Light gray background */
}

.portfolio-header {
    text-align: center;
    margin-bottom: 40px;
}

.portfolio-item {
    background-color: #ffffff; /* White background for each item */
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft box shadow */
    transition: transform 0.3s; /* Smooth transition for hover effect */
}

.portfolio-item:hover {
    transform: translateY(-5px); /* Lift the item up a bit when hovered */
}

.portfolio-title {
    font-size: 1.5em;
    margin-bottom: 15px;
}

.portfolio-description {
    color: #666; /* Grayish text color for description */
}

.portfolio-image {
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 15px;
}

.portfolio-link {
    color: #007bff; /* Blue color for links */
    text-decoration: none;
    transition: color 0.3s;
}

.portfolio-link:hover {
    color: #0056b3; /* Darker blue for hover */
}

/* Layout and Visual Improvements */
.portfolio-container {
    /* ... existing styles ... */
    padding: 40px;
}

.portfolio-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.create-link {
    background-color: #007bff;
    padding: 10px 15px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
}

.create-link:hover {
    background-color: #0056b3;
}

.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.portfolio-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    transition: transform 0.3s;
    overflow: hidden;
}

.portfolio-card:hover {
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.portfolio-image {
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 15px;
}

.portfolio-actions {
    margin-top: 10px;
}

.edit-link, .delete-link {
    margin-right: 10px;
    text-decoration: none;
    color: #007bff;
}

.delete-link {
    color: red;
}