.logout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This takes the full height of the viewport */
    background-color: #FFBA24; /* Optional: background color for the container */
  }
  
  .logout-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    /* Optional: add a hover effect */
    &:hover {
      background-color: #0056b3;
    }
  }
  