.vendor-container {
  background-color: #f0f2f5; /* Facebook-like background color... gross */
  color: #1c1e21;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vendor-header {
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.vendor-name {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.vendor-avatar {
  font-style: italic;
  color: #555;
}

.vendor-info p, .vendor-actions button, .vendor-edit-form label {
  margin-bottom: 15px;
  line-height: 1.6;
}

.vendor-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 8px;
}

.vendor-actions {
  display: flex;
  justify-content: center;
}

.edit-button, .update-button, .cancel-button {
  background-color: #1877f2; /* Facebook blue... Eww... */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 5px;
  font-weight: bold;
}

.edit-button:hover, .update-button:hover, .cancel-button:hover {
  background-color: #1654a1;
}

.vendor-edit-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.vendor-edit-form label {
  font-weight: bold;
  margin-bottom: 8px;
}

.vendor-edit-form input, .vendor-edit-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .vendor-container {
    padding: 15px;
  }

  .vendor-name {
    font-size: 1.8rem;
  }

  .vendor-info p, .vendor-actions button, .vendor-edit-form label {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .vendor-container {
    padding: 10px;
  }

  .vendor-name {
    font-size: 1.5rem;
  }

  .vendor-info p, .vendor-actions button, .vendor-edit-form label {
    font-size: 0.9rem;
  }
}

