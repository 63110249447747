/* Regular Desktop Styles */
body {
  background-color: #FFBA24;
}

/* Map Container */
.map-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.map-container .GoogleMap,
.map-container .FacebookPageEmbed {
  flex: 1;
  margin: 10px;
}

/* Home Container */
.home-container {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  color: blue;
  padding: 20px;
  letter-spacing: 0.05em;
  line-height: 1.5;
}

/* Image Section */
.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.image-section img {
  max-width: 100%;
  height: auto;
}

/* Highlights Section */
.highlights {
  padding: 20px 0;
  text-align: center;
  color: blue;
}

.highlights h2 {
  margin-bottom: 10px;
  color: blue;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 2.5em;
  word-spacing: 10px;
  letter-spacing: 2px;
}

.highlights p, .highlights li {
  padding: 5px;
  font-family: 'Merriweather', serif;
  font-size: 1.0em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}

.highlights ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
}

.highlights li {
  margin-bottom: 10px;
}

/* Events Styling */
.events-container, .upcoming-events {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.events-container {
  background-color: #FFBA24;
}

.upcoming-events h2, .event-title {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
}

.event-item {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}

.event-details {
  margin-bottom: 10px;
}

.event-date, .event-time, .event-location, .event-description {
  color: #333333;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 0.8em;
  display: block;
}

.event-description {
  color: #333333;
  margin: 10px 0;
}

.event-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  max-width: 100px;
  margin-top: 10px;
}

.event-link {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
}

.event-link:hover {
  background-color: #0056b3;
}

/* Additional styling for admin-vendor-selection, featured-vendors as needed */
.admin-vendor-selection, .featured-vendors {
  padding: 20px 0;
}

/* Button Styles */
.btn {
  padding: 10px 20px;
  margin-top: 20px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block; /* Ensure buttons are properly aligned */
}

.btn-primary {
  background-color: #28a745;
  color: white;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

/* Content Wrapper */
.content-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.highlights, .events {
  flex: 1;
  margin: 10px;
}

/* Vendor List */
.vendor-list {
  list-style-type: none;
  padding: 0;
}

.vendor-item {
  margin-bottom: 20px;
}

.vendor-image-container img {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.vendor-image-container img:hover {
  transform: scale(1.05);
}

/* Featured Vendors Container */
.featured-vendors-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

/* Vendor Image Container */
.vendor-image-container {
  display: grid;
  place-items: center;
}

.vendor-image {
  max-width: 100%;
  height: auto;
}

/* Modal Styles */
.modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
}

.modal-content {
  max-height: 80%;
  max-width: 80%;
  margin: auto;
  display: block;
  object-fit: contain;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

/* Facebook Container Styles */
.facebook-container {
  margin: 20px auto;
  padding: 15px;
  background-color: #fff; /* Optional: change as per your color scheme */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Adjust the width as per your layout requirements */
  text-align: center;
}

/* Container for both Facebook and Google Maps */
.social-media-maps-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

/* Individual Containers */
.facebook-container, .google-maps-container {
  flex: 1;
  max-width: calc(50% - 10px);
}

.vendor-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.vendor-item {
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Additional styling for each item */
}


/* Mobile Styles */
@media only screen and (max-width: 768px) {
  .vendor-list {
    grid-template-columns: 1fr; /* Single column layout */
  }
  /* Adjusts the Home Container for mobile */
  .home-container {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      text-align: center;
  }

  /* Adjusts the Image Section for mobile */
  .image-section {
      flex-direction: column;
      margin-bottom: 20px;
  }

  /* Adjusts Highlights Section font size for mobile */
  .highlights h2 {
      font-size: 1.5em;
  }

  /* Selected Vendor Section - Ensure this class is used in your HTML/JSX */
  .selected-vendor {
      margin-top: 20px;
      padding: 10px;
  }

  /* Adds space below the Upcoming Events section */
  .upcoming-events {
      margin-bottom: 30px;
  }

  /* Adjusts Google Maps and Facebook Embed for mobile */
  .map-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
  }

  .map-container .GoogleMap,
  .map-container .FacebookPageEmbed {
      width: 100%;
      margin: 10px 0;
  }

  /* Adds additional spacing between Google Maps and Facebook Embed */
  .map-container .GoogleMap {
      margin-bottom: 20px;
      margin-bottom: 20px;
  }

  /* Adjusts Event details font size for better readability */
  .event-date, .event-time, .event-location, .event-description {
      font-size: 1em;
  }

  /* Ensures modal content is appropriately sized on mobile */
  .modal-content {
      max-width: 90%;
      max-height: 90%;
  }

  /* Button adjustments for mobile */
  .btn {
      padding: 8px 16px;
      margin-top: 15px;
  }

  /* Content Wrapper adjustments for mobile */
  .content-wrapper {
      flex-direction: column;
  }

  /* Vendor List and Item adjustments for mobile */
  .vendor-list, .vendor-item {
      width: 100%;
  }

  /* Vendor Image adjustments for mobile */
  .vendor-image-container img {
      max-width: 100px;
  }

  .facebook-container {
    margin: 10px;
    padding: 10px;
    max-width: 100%;
}

.social-media-maps-container {
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.facebook-container, .google-maps-container {
  max-width: 100%;
}
  /* Adjustments for the Gallery container in mobile */
  .gallery-container {
    padding: 0;
    margin: 0 10px;
    max-width: calc(100% - 20px);
    overflow-x: hidden;
  }

  /* Additional styles specific to mobile */
  .gallery-container .l-box {
    padding: 1em;
    margin: 0;
  }

  .gallery-container .photo-box {
    max-width: calc(50% - 10px);
    margin: 5px;
  }

  .gallery-container .photo-box img {
    width: 100%;
    height: auto;
  }

  .vendors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .vendor-card {
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 10px;
  }
  
  .vendor-image {
    width: 100%;
    height: auto;
    border-radius: 5px;

  }

  /* Mobile view - Stacked layout */
@media (max-width: 600px) {
  .vendors-grid {
    grid-template-columns: 1fr; 
  }
}
}

